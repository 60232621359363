<template>
  <div class="mt-5">
    <div class="">
      <b-table
        :mobile-cards="false"
        class="is-striped"
        ref="table"
        :data="items"
        :loading="isLoading"
        paginated
        :per-page="50"
        aria-next-label="Neste side"
        aria-previous-label="Forrige side"
        aria-page-label="side"
        aria-current-label="Gjeldende side"
      >
        <b-table-column field="id" label="ID" v-slot="props" sortable>
          <router-link
            v-if="props.row.id"
            :to="{ name: 'item.edit', params: { id: props.row.id } }"
          >
            {{ props.row.id }}
          </router-link>
        </b-table-column>

        <b-table-column field="title" label="Tittel" v-slot="props">
          {{ props.row.title }}
        </b-table-column>

        <b-table-column
          field="checkedOut"
          sortable
          label="Utmeldt"
          v-slot="props"
        >
          {{ helpers.methods.getFormattedDateTime(props.row.checkedOut) }}
        </b-table-column>
        <b-table-column
          field="delivered"
          sortable
          label="Utlevert"
          v-slot="props"
        >
          {{ helpers.methods.getFormattedDateTime(props.row.delivered) }}
        </b-table-column>
        <b-table-column
          field="accounting_storageInvoiceDate"
          sortable
          label="Fakturert lagerleie dato"
          v-slot="props"
        >
          {{
            helpers.methods.getFormattedDateTime(
              props.row.accounting_storageInvoiceDate
            )
          }}
        </b-table-column>
        <b-table-column
          field="accounting_storageInvoicePaymentDate"
          sortable
          label="Betalt lagerleie dato"
          v-slot="props"
        >
          {{
            helpers.methods.getFormattedDateTime(
              props.row.accounting_storageInvoicePaymentDate
            )
          }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script setup>
import helpers from "../../helpers";
import { ref, getCurrentInstance } from "vue";

const items = ref([]);
const isLoading = ref(false);

const app = getCurrentInstance().proxy;

const loadAsyncData = () => {
  isLoading.value = true;
  app.$axios
    .get(`${window.apiURL}/admin/dashboard/storage`)
    .then(response => {
      isLoading.value = false;
      items.value = response.data;
    })
    .catch(e => {
      isLoading.value = false;
      app.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: "is-danger",
        queue: false
      });
    });
};

loadAsyncData();
</script>

<style lang="scss" scoped></style>
