import axios from "axios";

export default {
  namespaced: true,
  state: {
    selectedModules: []
  },
  mutations: {
    SET_SELECTED_MODULES(state, modules) {
      state.selectedModules = modules;
    }
  }
};
