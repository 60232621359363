<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand nav-search">
      <a
        @click.prevent="menuToggle"
        :title="toggleTooltip"
        class="navbar-item is-desktop-icon-only is-hidden-touch"
      >
        <b-icon :icon="menuToggleIcon" />
      </a>
      <a
        class="navbar-item is-hidden-desktop"
        @click.prevent="menuToggleMobile"
      >
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
      <div class="navbar-item no-left-space has-control search-input">
        <div class="control">
          <b-field label="">
            <b-input
              autofocus
              :placeholder="
                `Søk etter ${queryExample2} eller ${queryExample}. CTRL + Enter om du ikke vil gå direkte til ID`
              "
              field="title"
              v-model="search"
              icon-right="magnify"
              icon-right-clickable
              v-on:keydown.enter.native="manualSearch"
              @icon-right-click="manualSearch"
              class="is-small-desktop"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>

    <div class="navbar-brand is-right is-hidden-desktop">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="updatesToggle"
      >
        <b-icon icon="bell" custom-size="default" />
      </a>
      <a
        v-if="isChatEnabled"
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="chatToggle"
      >
        <b-icon icon="message" custom-size="default" />
      </a>
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuNavBarActive }"
    >
      <div class="navbar-end">
        <nav-bar-menu class="has-divider has-user-avatar">
          <user-avatar />
          <div class="is-user-name">
            <span> {{ getUser ? getUser.email : "" }}</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <router-link
              :to="`/user/${getUser ? getUser.uid : ''}`"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon icon="account" custom-size="default" />
              <span>Min profil</span>
            </router-link>

            <hr class="navbar-divider" />
            <a class="navbar-item" title="Logg ut" @click="logout">
              <b-icon icon="logout" custom-size="default" />
              <span>Logg ut</span>
            </a>
          </div>
        </nav-bar-menu>
        <!-- <a class="navbar-item has-divider changelog"></a>!-->

        <a
          v-if="isUserAdmin || isUserSuper"
          class="navbar-item has-divider is-desktop-icon-only"
          @click="$router.push({ name: 'dashboard', params: { tab: 'stats' } })"
        >
          <div
            class="tag"
            :class="{
              'is-success': currentVisitors >= 100,
              'is-light': currentVisitors < 100
            }"
          >
            <b-tooltip
              label="Aktive besøkende nå"
              type="is-dark"
              position="is-bottom"
            >
              <b-icon
                icon="account-group"
                size="is-small"
                class="mr-1"
              ></b-icon>
              {{ currentVisitors }} besøkende
            </b-tooltip>
          </div>
        </a>

        <a
          class="navbar-item has-divider is-desktop-icon-only"
          title="Dark mode"
          @click="darkModeToggle"
        >
          <b-icon :icon="darkModeToggleIcon" custom-size="default" />
          <span>Dark mode</span>
        </a>

        <a
          v-if="!isUserAgent"
          class="navbar-item has-divider is-desktop-icon-only"
          :class="{ 'is-active': isAsideRightVisible }"
          title="Updates"
          @click.prevent="updatesToggle"
        >
          <b-icon
            icon="bell"
            custom-size="default"
            :class="{ 'has-update-mark': hasUpdates }"
          />
          <span>Oppdateringer</span>
        </a>
        <a
          v-if="isChatEnabled"
          class="navbar-item has-divider is-desktop-icon-only"
          :class="{ 'is-active': isChatVisible }"
          title="Updates"
          @click.prevent="chatToggle"
        >
          <b-icon
            icon="message"
            custom-size="default"
            :class="{ 'has-update-mark': hasChatUpdates }"
          />
          <span>Oppdateringer</span>
        </a>
        <a
          v-if="!isUserAgent"
          class="navbar-item has-divider is-desktop-icon-only"
          title="Favoritter"
          @click="favorites"
        >
          <b-icon icon="star" custom-size="default" />
          <span>Favoritter</span>
        </a>
        <a
          class="navbar-item is-desktop-icon-only"
          title="Logg ut"
          @click="logout"
        >
          <b-icon icon="logout" custom-size="default" />
          <span>Logg ut</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import NavBarMenu from "@/components/NavBarMenu";
import Favorites from "@/components/Favorites";
import UserAvatar from "@/components/UserAvatar";
import debounce from "lodash/debounce";
import helpers from "../helpers";

export default {
  name: "NavBar",
  components: {
    UserAvatar,
    NavBarMenu,
    Favorites
  },
  mixins: [helpers],

  created() {
    // Create new axios instance to avoid firebase auth injection
    this.plausibleAxios = this.$axios.create({
      baseURL: "https://plausible.io/api/v2"
    });
  },

  data() {
    let queryExamples = [
      "Bilmerke",
      "Bilmodell",
      "Transportfirma",
      "Regnr",
      "Kategori",
      "Referansenummer",
      "E-post"
    ];

    let queryExamples2 = ["BrukerID", "ObjektID"];

    return {
      isMenuNavBarActive: false,
      queryExample:
        queryExamples[
          Math.floor(Math.random() * (queryExamples.length - 0) + 0)
        ],
      queryExample2:
        queryExamples2[
          Math.floor(Math.random() * (queryExamples2.length - 0) + 0)
        ],
      isFetching: false,
      search: null,
      data: [],
      plausibleToken: import.meta.env.VITE_PLAUSIBLE_TOKEN,
      plausibleSiteId: import.meta.env.VITE_PLAUSIBLE_SITE_ID,
      plausibleAxios: null
    };
  },
  computed: {
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? "close" : "dots-vertical";
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
    },
    menuToggleIcon() {
      return this.isAsideExpanded ? "backburger" : "forwardburger";
    },
    toggleTooltip() {
      return this.isAsideExpanded ? "Collapse" : "Expand";
    },
    darkModeToggleIcon() {
      return this.isDarkModeActive ? "white-balance-sunny" : "weather-night";
    },
    ...mapState([
      "isNavBarVisible",
      "isAsideExpanded",
      "isAsideMobileExpanded",
      "isAsideRightVisible",
      "isChatEnabled",
      "isAllowedChat",
      "isChatVisible",
      "isDarkModeActive",
      "userName",
      "hasUpdates",
      "hasChatUpdates"
    ]),
    ...mapGetters([
      "getUser",
      "getLoginStatus",
      "isUserAgent",
      "isUserAdmin",
      "isUserSuper"
    ]),
    currentVisitors: {
      get() {
        return this.$store.state.currentVisitors;
      },
      set(value) {
        this.$store.commit("setCurrentVisitors", value);
      }
    }
  },
  methods: {
    async checkForNewChatMessages() {
      let itemId = this.$route.params.id;

      if (itemId) {
        let chatMessages = await this.$axios.get(
          `${window.apiURL}/admin/items/${itemId}/countChatMessages`
        );

        // fetch chat message counts from localstorage
        const chatUpdates = JSON.parse(
          localStorage.getItem("hasChatUpdates") ?? "{}"
        );

        if (!chatUpdates[itemId]) {
          chatUpdates[itemId] = 0;
        }

        if (chatMessages.data.count > chatUpdates[itemId]) {
          // update hasChatUpdates mapstate to true
          this.$store.commit("setHasChatUpdates", true);
        } else {
          this.$store.commit("setHasChatUpdates", false);
        }
      }
    },
    async checkForNewReminders() {
      let reminders = await this.$axios.get(
        `${window.apiURL}/admin/reminders/countReminders`
      );

      if (reminders.data.count > this.$cookies.get("hasUpdates")) {
        // update cookies
        this.$cookies.set("hasUpdates", reminders.data.count);

        // update hasUpdates mapstate to true
        this.$store.commit("setHasUpdates", true);
      } else {
        this.$store.commit("setHasUpdates", false);
      }
    },
    ...mapActions(["signOutAction"]),

    menuToggle() {
      this.$store.commit("asideStateToggle");
    },
    menuToggleMobile() {
      this.$store.commit("asideMobileStateToggle");
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
    },
    updatesToggle() {
      this.$store.commit("chatToggle", false);
      this.$store.commit("asideRightToggle");
    },
    chatToggle() {
      this.$store.commit("asideRightToggle", false);
      this.$store.commit("chatToggle");
    },
    darkModeToggle() {
      this.$store.commit("darkModeToggle");
    },
    favorites() {
      this.$buefy.modal.open({
        parent: this,
        component: Favorites,
        hasModalCard: true,
        trapFocus: true,
        width: 960
      });
    },
    logout() {
      this.signOutAction();
      this.$router.push({
        path: "/login"
      });
    },

    manualSearch(event) {
      let query = this.search;
      this.search = "";

      if (!query) {
        return;
      }

      // regex patterns for user id or email
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const userIdPattern = /^[a-z0-9]{28}$/;
      const idPattern = /^ID[0-9]+$/;

      if (emailPattern.test(query)) {
        this.$router.push({
          name: "users",
          query: { q: query }
        });
      } else if (userIdPattern.test(query)) {
        this.$router.push({
          name: "user.edit",
          params: { id: query }
        });
      } else if (idPattern.test(query)) {
        // remove ID from query
        query = query.replace("ID", "");

        this.$router.push({
          name: "item.edit",
          params: { id: query }
        });
      } else if (
        this.validIdLookup(query) &&
        !(event?.metaKey || event?.ctrlKey)
      ) {
        this.$router
          .push({
            name: "item.edit",
            params: { id: query }
          })
          .catch(err => {
            let errorMessage = `Noe gikk galt. Send denne til admin: ${err.name}`;
            if (err.name == "NavigationDuplicated") {
              errorMessage = `Du er allerede på denne siden. Prøv å last inn hele siden på nytt`;
            }

            this.$buefy.toast.open({
              message: errorMessage,
              type: "is-danger"
            });
          });
      } else {
        this.$router.push({
          name: "items",
          query: { q: query }
        });
      }
    },
    pickedItem(item) {
      this.$router.push({
        name: "item.edit",
        params: { id: item.document.id }
      });
    },

    getAsyncData: debounce(function(name) {
      if (!name.length) {
        this.data = [];
        return;
      }
      this.isFetching = true;
      this.$axios
        .get(`${window.apiURL}/admin/items`, {
          params: {
            q: name
          }
        })
        .then(({ data }) => {
          this.data = [];
          data.items.forEach(item => this.data.push(item));
        })
        .catch(error => {
          this.data = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),

    async getPlausibleStats() {
      if (!this.plausibleToken || !this.plausibleSiteId) {
        console.error("Plausible configuration missing");
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${this.plausibleToken}`,
            "Content-Type": "application/json"
          }
        };

        const data = {
          site_id: this.plausibleSiteId,
          metrics: ["visitors"],
          date_range: [
            new Date(Date.now() - 5 * 60 * 1000).toISOString(),
            new Date().toISOString()
          ]
        };

        const response = await this.plausibleAxios.post("/query", data, config);
        const visitors = response.data.results[0]?.metrics[0] || 0;
        this.$store.commit("setCurrentVisitors", visitors);
      } catch (error) {
        console.error("Error fetching Plausible stats:", error);
      }
    },

    startVisitorsPolling() {
      // Update realtime stats every minute
      this.visitorsInterval = setInterval(() => {
        this.getPlausibleStats();
      }, 300000); // 5 minutes
    }
  },
  async mounted() {
    //Inject Headway app script
    let headway = document.getElementById("headway-script");
    if (headway) return; //Script already appended

    //Create promise which resolves on script load
    let promise = new Promise((resolve, reject) => {
      let script = document.createElement("script");
      script.src = "//cdn.headwayapp.co/widget.js";
      script.id = "headway-script";
      script.setAttribute("async", true);
      document.head.appendChild(script);
      script.onload = resolve; //Resolve when loaded
      script.onerror = reject;
    });
    await promise; //Await for the script to be resolved

    //Init the widget now that the script has loaded
    // we do this in watcher

    // Initialize visitors count
    await this.getPlausibleStats();
    this.startVisitorsPolling();
  },

  watch: {
    isChatEnabled() {
      // okay, chat is enabled, lets check if we have any updates
      this.checkForNewChatMessages();
    },
    getLoginStatus(newValue) {
      if (newValue == "loggedIn") {
        if (this.isUserAdmin || this.isUserSuper) {
          // eslint-disable-next-line no-undef
          Headway.init({
            selector: ".changelog",
            account: "76ZRD7"
          });

          // check if there are new reminders
          this.checkForNewReminders();

          // check for new reminders every hour
          setInterval(() => {
            this.checkForNewReminders();
          }, 3600000);
        }
      }
    }
  },

  beforeDestroy() {
    if (this.visitorsInterval) {
      clearInterval(this.visitorsInterval);
    }
  }
};
</script>
