<template>
  <div class="dashboard-widgets">
    <div class="columns is-multiline">
      <!-- Analytics Widget -->
      <div class="column is-12">
        <div class="dashboard-widget">
          <div class="widget-header">
            <div class="header-title">
              <span class="title is-5"
                >Statistikk for uke
                {{
                  new Date().getWeek
                    ? new Date().getWeek()
                    : Math.ceil(
                        (new Date() -
                          new Date(new Date().getFullYear(), 0, 1)) /
                          604800000
                      )
                }}
                ({{
                  new Date().getDay() === 1
                    ? $t("monday")
                    : `${$t("monday")} - ${$t(
                        [
                          "monday",
                          "tuesday",
                          "wednesday",
                          "thursday",
                          "friday"
                        ][new Date().getDay() - 1]
                      )}`
                }})</span
              >
            </div>
          </div>
          <div class="widget-content">
            <template v-if="isLoading">
              <b-skeleton height="150" width="100%"></b-skeleton>
            </template>
            <template v-else>
              <div class="columns">
                <!-- Live visitors -->
                <div class="column is-4">
                  <div
                    class="has-text-centered analytics-box has-background-primary-light"
                  >
                    <p class="heading has-text-primary-dark">
                      Besøkende akkurat nå
                    </p>
                    <p class="title has-text-primary-dark">
                      {{ formatNumber(currentVisitors) || 0 }}
                    </p>
                  </div>
                </div>
                <!-- Weekly stats combined -->
                <div class="column is-4">
                  <div
                    class="has-text-centered analytics-box has-background-info-light"
                  >
                    <p class="heading has-text-info-dark">
                      Besøkende så langt
                    </p>
                    <p class="title has-text-info-dark">
                      <b-tooltip type="is-black" label="Unike besøkende">
                        {{ formatNumber(analytics.weeklyVisitors) || 0 }}
                      </b-tooltip>
                      <span class="has-text-grey-dark mx-2">/</span>
                      <b-tooltip type="is-black" label="Sidevisninger">
                        {{ formatNumber(analytics.weeklyPageviews) || 0 }}
                      </b-tooltip>
                    </p>
                  </div>
                </div>
                <!-- Most popular page -->
                <div class="column is-4">
                  <div
                    class="has-text-centered analytics-box"
                    :class="{
                      'has-background-success-light': !analytics.popularPage
                        ?.thumbnail,
                      'has-background-image': analytics.popularPage?.thumbnail
                    }"
                    :style="
                      analytics.popularPage?.thumbnail
                        ? {
                            backgroundImage: `url(${mediaPathResized(
                              analytics.popularPage.thumbnail
                            )})`
                          }
                        : {}
                    "
                  >
                    <div
                      class="overlay"
                      v-if="analytics.popularPage?.thumbnail"
                    ></div>
                    <div class="content-wrapper">
                      <p class="heading has-text-success-dark">
                        Mest populære objekt
                      </p>
                      <p
                        class="title has-text-success-dark is-size-6 has-text-weight-semibold"
                      >
                        <template v-if="analytics.popularPage?.itemId">
                          <router-link
                            :to="{
                              name: 'item.edit',
                              params: { id: analytics.popularPage.itemId }
                            }"
                            class="has-text-success-dark"
                          >
                            <div
                              class="popular-item-title is-clearfix is-ellipsis mb-2"
                            >
                              {{ analytics.popularPage.title }}
                            </div>
                          </router-link>
                        </template>
                        <template v-else>Ingen data</template>
                      </p>
                      <p
                        class="subtitle is-7 has-text-success-dark mt-2"
                        v-if="analytics.popularPage"
                      >
                        {{ formatNumber(analytics.popularPage.pageviews) }}
                        visninger
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- Operations Widget -->
      <div class="column is-6">
        <div class="dashboard-widget">
          <div class="widget-header">
            <div class="header-title">
              <span class="title is-5">Driftsmeldinger</span>
            </div>
            <b-button
              @click="navigateTo('messages')"
              icon-right="launch"
              size="is-medium"
              type="is-text"
            />
          </div>
          <div class="widget-content">
            <template v-if="isLoading">
              <b-skeleton height="150" width="100%"></b-skeleton>
            </template>
            <template v-else>
              <b-message v-if="messages[0]" :type="messages[0].type" has-icon>
                <span
                  class="is-size-5"
                  v-html="$nl2br(messages[0].message)"
                ></span>
              </b-message>
              <p v-else class="has-text-grey">No messages available.</p>
            </template>
          </div>
        </div>
      </div>

      <!-- Items Widget -->
      <div class="column is-6">
        <div class="dashboard-widget">
          <div class="widget-header">
            <div class="header-title">
              <span class="title is-5">Objekter som trenger oppfølging</span>
            </div>
            <b-button
              @click="navigateTo('items')"
              icon-right="launch"
              size="is-medium"
              type="is-text"
            />
          </div>
          <div class="widget-content">
            <template v-if="isLoading">
              <div
                class="columns widget-list-item is-fullwidth p-0"
                v-for="i in 5"
                :key="i"
              >
                <div class="column">
                  <b-skeleton height="20" width="90%"></b-skeleton>
                </div>
                <div class="column is-one-quarter">
                  <b-skeleton height="20" width="100%"></b-skeleton>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(item, index) in items.slice(0, 5)"
                :key="index"
                class="widget-list-item"
              >
                <div class="is-clearfix is-ellipsis">
                  <router-link
                    v-if="item.id"
                    :to="{ name: 'item.edit', params: { id: item.id } }"
                  >
                    {{ item.title }}
                  </router-link>
                </div>

                <b-tag
                  size="is-medium"
                  :type="
                    new Date(item.createdAt) <
                    new Date().setFullYear(new Date().getFullYear() - 1)
                      ? 'is-danger'
                      : 'is-info'
                  "
                  :class="{
                    'animate-wiggle':
                      new Date(item.createdAt) <
                      new Date().setFullYear(new Date().getFullYear() - 1)
                  }"
                  >{{
                    getFormattedDateTime(item.createdAt, "DD.MM.YYYY")
                  }}</b-tag
                >
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- Payments Widget -->
      <div class="column is-6">
        <div class="dashboard-widget">
          <div class="widget-header">
            <div class="header-title">
              <span class="title is-5">Innbetalinger</span>
            </div>
            <b-button
              @click="navigateTo('payments')"
              icon-right="launch"
              size="is-medium"
              type="is-text"
            />
          </div>
          <div class="widget-content">
            <template v-if="isLoading">
              <div
                class="columns widget-list-item is-fullwidth p-0"
                v-for="i in 5"
                :key="i"
              >
                <div class="column">
                  <b-skeleton height="20" width="90%"></b-skeleton>
                </div>
                <div class="column is-one-quarter">
                  <b-skeleton height="20" width="100%"></b-skeleton>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(payment, index) in payments.slice(0, 5)"
                :key="index"
                class="widget-list-item"
              >
                <div class="is-clearfix is-ellipsis">
                  <router-link
                    v-if="payment.id"
                    :to="{ name: 'item.edit', params: { id: payment.id } }"
                  >
                    #{{ payment?.item?.title }}
                  </router-link>
                </div>

                <b-tag type="is-success" v-if="payment.paymentReceivedDate"
                  >Betalt</b-tag
                >
                <b-tag
                  type="is-warning"
                  v-else-if="payment?.item?.sale.contractSigningStatus"
                  >{{
                    $t(
                      `items.saleModule.${payment?.item?.sale.contractSigningStatus}`
                    )
                  }}</b-tag
                >
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- Storage Widget -->
      <div class="column is-6">
        <div class="dashboard-widget">
          <div class="widget-header">
            <div class="header-title">
              <span class="title is-5">Lagerleie</span>
            </div>
            <b-button
              @click="navigateTo('storage')"
              icon-right="launch"
              size="is-medium"
              type="is-text"
            />
          </div>
          <div class="widget-content">
            <template v-if="isLoading">
              <div
                class="columns widget-list-item is-fullwidth p-0"
                v-for="i in 5"
                :key="i"
              >
                <div class="column">
                  <b-skeleton height="20" width="90%"></b-skeleton>
                </div>
                <div class="column is-one-quarter">
                  <b-skeleton height="20" width="100%"></b-skeleton>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(storageItem, index) in storage.slice(0, 5)"
                :key="index"
                class="widget-list-item"
              >
                <div class="is-clearfix is-ellipsis">
                  <router-link
                    v-if="storageItem.id"
                    :to="{ name: 'item.edit', params: { id: storageItem.id } }"
                  >
                    {{ storageItem.title }}
                  </router-link>
                </div>
                <b-tag
                  type="is-success"
                  v-if="storageItem.accounting_storageInvoicePaymentDate"
                >
                  <b-tooltip
                    type="is-black"
                    :label="
                      getFormattedDateTime(
                        storageItem.accounting_storageInvoicePaymentDate
                      )
                    "
                    >Betalt</b-tooltip
                  >
                </b-tag>
                <b-tag
                  type="is-warning"
                  v-else-if="storageItem.accounting_storageInvoiceDate"
                >
                  <b-tooltip
                    type="is-black"
                    :label="
                      getFormattedDateTime(
                        storageItem.accounting_storageInvoiceDate
                      )
                    "
                    >Fakturert</b-tooltip
                  >
                </b-tag>
                <b-tag type="is-danger" v-else>
                  Ikke fakturert
                </b-tag>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from "@/helpers";
export default {
  mixins: [helpers],
  data() {
    return {
      isLoading: true,
      items: [],
      messages: [],
      payments: [],
      storage: [],
      analytics: {
        currentVisitors: 0,
        weeklyVisitors: 0,
        weeklyPageviews: 0,
        popularPage: null
      },
      plausibleToken: import.meta.env.VITE_PLAUSIBLE_TOKEN,
      plausibleSiteId: import.meta.env.VITE_PLAUSIBLE_SITE_ID,
      plausibleAxios: null
    };
  },
  created() {
    // we need to create a new axios instance to avoid firebase auth injection
    // fucking hell :D
    this.plausibleAxios = this.$axios.create({
      baseURL: "https://plausible.io/api/v2"
    });
  },
  async mounted() {
    await Promise.all([
      this.getItems(),
      this.getMessages(),
      this.getPayments(),
      this.getStorage(),
      this.getPlausibleStats()
    ]);
    this.startAnalyticsPolling();
    this.isLoading = false;
  },
  methods: {
    async getItems() {
      await this.$axios
        .get(`${window.apiURL}/admin/dashboard/items`, {
          params: {
            widget: true
          }
        })
        .then(response => {
          this.items = response.data.items;
          this.$emit("update-count", {
            id: "items",
            count: response.data.total
          });
        });
    },
    async getMessages() {
      await this.$axios
        .get(`${window.apiURL}/admin/dashboard/messages`)
        .then(response => {
          this.messages = response.data;
          this.$emit("update-count", {
            id: "messages",
            count: response.data.length
          });
        });
    },
    async getPayments() {
      await this.$axios
        .get(`${window.apiURL}/admin/dashboard/settlements`)
        .then(response => {
          this.payments = response.data;
          this.$emit("update-count", {
            id: "payments",
            count: response.data.length
          });
        });
    },
    async getStorage() {
      await this.$axios
        .get(`${window.apiURL}/admin/dashboard/storage`)
        .then(response => {
          this.storage = response.data;
          this.$emit("update-count", {
            id: "storage",
            count: response.data.length
          });
        });
    },
    async getPlausibleStats() {
      if (!this.plausibleToken || !this.plausibleSiteId) {
        console.error("Plausible configuration missing");
        return;
      }

      // skip in development mode
      if (import.meta.env.DEV) {
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${this.plausibleToken}`,
            "Content-Type": "application/json"
          }
        };

        const monday = new Date();
        monday.setDate(monday.getDate() - monday.getDay() + 1);
        const friday = new Date(monday);
        friday.setDate(friday.getDate() + 4);

        const [weeklyResponse, pagesResponse] = await Promise.all([
          this.plausibleAxios.post(
            "/query",
            {
              site_id: this.plausibleSiteId,
              metrics: ["visitors", "pageviews"],
              date_range: [
                monday.toISOString().split("T")[0],
                friday.toISOString().split("T")[0]
              ]
            },
            config
          ),
          this.plausibleAxios.post(
            "/query",
            {
              site_id: this.plausibleSiteId,
              metrics: ["pageviews"],
              date_range: [
                monday.toISOString().split("T")[0],
                friday.toISOString().split("T")[0]
              ],
              filters: [["matches", "event:page", ["/items/[0-9]+"]]],
              dimensions: ["event:page"],
              order_by: [["pageviews", "desc"]]
            },
            config
          )
        ]);

        const itemId = this.extractItemId(
          pagesResponse.data.results[0].dimensions[0]
        );

        let itemDetails = null;
        if (itemId) {
          itemDetails = await this.fetchItemDetails(itemId);
        }

        this.analytics = {
          currentVisitors: this.$store.state.currentVisitors,
          weeklyVisitors: weeklyResponse.data.results[0]?.metrics[0] || 0,
          weeklyPageviews: weeklyResponse.data.results[0]?.metrics[1] || 0,
          popularPage:
            pagesResponse.data.results?.[0] && itemDetails
              ? {
                  pageviews: pagesResponse.data.results[0].metrics[0],
                  itemId: itemId,
                  title: itemDetails.title,
                  thumbnail: itemDetails.thumbnail
                }
              : null
        };
      } catch (error) {
        console.error("Error fetching Plausible stats:", error);
      }
    },
    startAnalyticsPolling() {
      // skip in development mode
      if (import.meta.env.DEV) {
        return;
      }

      // update realtime stats
      this.analyticsInterval = setInterval(() => {
        this.getPlausibleStats();
      }, 300000); // 5 minutes
    },
    navigateTo(route) {
      this.$router.push({ name: "dashboard", params: { tab: route } });
    },
    formatNumber(num) {
      return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0";
    },
    extractItemId(path) {
      if (!path || typeof path !== "string") return null;
      const match = path.match(/\/items\/(\d+)/);
      return match ? match[1] : null;
    },
    async fetchItemDetails(itemId) {
      try {
        const response = await this.$axios.get(
          `${window.apiURL}/admin/items/${itemId}`
        );
        return {
          id: response.data.id,
          title: response.data.title,
          thumbnail: response.data.sale?.thumbnailPath
        };
      } catch (error) {
        console.error("Error fetching item details:", error);
        return null;
      }
    }
  },
  beforeDestroy() {
    if (this.analyticsInterval) {
      clearInterval(this.analyticsInterval);
    }
  },
  computed: {
    currentVisitors() {
      return this.$store.state.currentVisitors;
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard-widgets {
  padding: 1.5rem;

  .dashboard-widget {
    height: 100%;

    padding: 1rem;
  }

  .widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #dbdbdb;

    .header-title {
      display: flex;
      align-items: center;
    }
  }

  .widget-content {
    .widget-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;
      border-bottom: 1px solid #f5f5f5;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.analytics-box {
  padding: 1.5rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.has-background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .heading,
    .title,
    .subtitle {
      color: white !important;
      position: relative;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }

    a {
      color: white !important;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    z-index: 1;
  }

  .content-wrapper {
    position: relative;
    z-index: 2;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .heading {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
  }

  .title {
    margin-bottom: 0 !important;
  }

  .subtitle {
    margin-top: -0.5rem !important;
  }

  .title.is-size-6 {
    word-break: break-word;
    line-height: 1.4;
  }

  .popular-item-title {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.animate-wiggle {
  animation: wiggle 0.3s ease-in-out infinite;

  &:hover {
    animation-play-state: paused;
  }
}
</style>
