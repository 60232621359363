<template>
  <div>
    <section class="mt-5">
      <div v-if="isUserSuper">
        <b-button
          type="is-black"
          icon-left="plus"
          @click="isModalActive = true"
          class="is-pulled-right"
        >
          Ny driftmelding
        </b-button>

        <b-modal
          v-model="isModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-modal
        >
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Ny driftmelding</p>
              <button
                type="button"
                class="delete"
                @click="isModalActive = false"
              ></button>
            </header>
            <section class="modal-card-body">
              <b-field label="Type">
                <b-select
                  v-model="messageType"
                  expanded
                  placeholder="Velg type"
                >
                  <option value="">Velg type</option>
                  <option value="is-info">Info</option>
                  <option value="is-success">Success</option>
                  <option value="is-warning">Warning</option>
                  <option value="is-danger">Danger</option>
                </b-select>
              </b-field>
              <b-field label="Melding">
                <b-input
                  type="textarea"
                  v-model="newMessage"
                  placeholder="Skriv inn en ny melding her..."
                  maxlength="500"
                ></b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <b-button
                type="is-primary"
                :loading="isSaving"
                @click="
                  saveMessage();
                  isModalActive = false;
                "
              >
                Lagre
              </b-button>
              <b-button @click="isModalActive = false">Avbryt</b-button>
            </footer>
          </div>
        </b-modal>
      </div>

      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>

      <div class="messages mt-5">
        <div v-if="messages.length === 0" class="has-text-centered">
          <p>Ingen driftmeldinger funnet</p>
        </div>

        <div v-for="message in messages" :key="message.id" class="mb-4">
          <b-message :type="message.type">
            <div class="is-flex is-justify-content-space-between">
              <p v-html="$nl2br(message.message)"></p>
              <b-button
                v-if="isUserSuper"
                type="is-ghost"
                icon-left="pencil"
                size="is-small"
                @click="editMessage(message)"
              />
            </div>
            <p class="is-size-7 has-text-grey">
              {{ getFormattedDateTime(message.createdAt) }}
            </p>
          </b-message>
        </div>
      </div>
    </section>

    <b-modal
      v-model="isEditModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Rediger driftmelding</p>
          <button
            type="button"
            class="delete"
            @click="isEditModalActive = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="Type">
            <b-select
              v-model="editedMessage.type"
              expanded
              placeholder="Velg type"
            >
              <option value="">Velg type</option>
              <option value="is-info">Info</option>
              <option value="is-success">Success</option>
              <option value="is-warning">Warning</option>
              <option value="is-danger">Danger</option>
            </b-select>
          </b-field>
          <b-field label="Melding">
            <b-input
              type="textarea"
              v-model="editedMessage.message"
              placeholder="Skriv inn melding her..."
              maxlength="500"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            type="is-primary"
            :loading="isSaving"
            @click="updateMessage"
          >
            Lagre
          </b-button>
          <b-button @click="isEditModalActive = false">Avbryt</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import helpers from "../../helpers";

export default {
  name: "Messages",
  mixins: [helpers],
  components: {},
  computed: {
    ...mapGetters(["isUserSuper", "isUserAdmin", "isUserAgent", "getUser"])
  },
  data() {
    return {
      messages: [],
      isLoading: false,
      isSaving: false,
      newMessage: "",
      messageType: "",
      isModalActive: false,
      isEditModalActive: false,
      editedMessage: {
        id: null,
        message: "",
        type: ""
      }
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const response = await this.$axios.get(
          `${window.apiURL}/admin/dashboard/messages`
        );
        console.log(response.data);
        this.messages = response.data;
      } catch (error) {
        this.$buefy.toast.open({
          message: `Error: ${error.message}`,
          type: "is-danger",
          queue: false
        });
      } finally {
        this.isLoading = false;
      }
    },
    async saveMessage() {
      this.isSaving = true;
      try {
        await this.$axios.post(`${window.apiURL}/admin/dashboard/messages`, {
          message: this.newMessage,
          type: this.messageType
        });

        // Show success message
        this.$buefy.toast.open({
          message: "Melding lagret",
          type: "is-success"
        });

        // Reset form
        this.newMessage = "";
        this.messageType = "";

        // Refresh messages list
        await this.getData();
      } catch (error) {
        this.$buefy.toast.open({
          message: `Error: ${error.message}`,
          type: "is-danger",
          queue: false
        });
      } finally {
        this.isSaving = false;
      }
    },
    editMessage(message) {
      this.editedMessage = {
        id: message.id,
        message: message.message,
        type: message.type
      };
      this.isEditModalActive = true;
    },
    async updateMessage() {
      this.isSaving = true;
      try {
        await this.$axios.post(`${window.apiURL}/admin/dashboard/messages`, {
          id: this.editedMessage.id,
          message: this.editedMessage.message,
          type: this.editedMessage.type
        });

        // Show success message
        this.$buefy.toast.open({
          message: "Melding oppdatert",
          type: "is-success"
        });

        // Close modal and reset form
        this.isEditModalActive = false;
        this.editedMessage = {
          id: null,
          message: "",
          type: "is-info"
        };

        // Refresh messages list
        await this.getData();
      } catch (error) {
        this.$buefy.toast.open({
          message: `Error: ${error.message}`,
          type: "is-danger",
          queue: false
        });
      } finally {
        this.isSaving = false;
      }
    }
  },
  async mounted() {
    await this.getData();
  }
};
</script>

<style lang="scss" scoped></style>
