<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section
      class="section is-main-section dashboard"
      v-if="isUserAdmin || isUserSuper"
    >
      <b-tabs v-model="activeTab">
        <b-tab-item
          v-for="tab in tabs"
          :key="tab.id"
          :icon="tab.icon"
          :value="tab.id"
          :label="tab.label"
        >
          <template #header>
            <b-icon :icon="tab.icon"></b-icon>
            <span>
              {{ tab.label }}
              <b-tag type="is-warning" rounded v-if="tab.count">
                {{ tab.count }}
              </b-tag>
            </span>
          </template>
          <component
            v-if="activeTab === tab.id"
            :is="tab.component"
            @update-count="updateTabCount(tab.id, $event)"
          />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script async src="https://plausible.io/js/embed.host.js"></script>
<script>
import TitleBar from "@/components/TitleBar";
import Items from "./home/Items.vue";
import Statistics from "./home/Statistics.vue";
import Payments from "./home/Payments.vue";
import Messages from "./home/Messages.vue";
import Storage from "./home/Storage.vue";
import Widgets from "./home/Widgets.vue";
import { mapGetters } from "vuex";

export default {
  name: "home",
  data() {
    return {
      activeTab: "widgets",
      tabs: [
        {
          id: "widgets",
          label: "Dashboard",
          icon: "widgets",
          component: Widgets
        },
        {
          id: "items",
          label: "Objekter",
          icon: "car-brake-alert",
          component: Items,
          hasCount: true,
          count: 0
        },
        {
          id: "messages",
          label: "Driftsmeldinger",
          icon: "message-alert",
          component: Messages,
          hasCount: true,
          count: 0
        },
        {
          id: "stats",
          label: "Statistikk",
          icon: "chart-bar",
          component: Statistics
        },
        {
          id: "payments",
          label: "Innbetalinger",
          icon: "bank-check",
          component: Payments,
          hasCount: true,
          count: 0
        },
        {
          id: "storage",
          label: "Lagerleie",
          icon: "warehouse",
          component: Storage,
          hasCount: true,
          count: 0
        }
      ]
    };
  },

  mounted() {
    // Set active tab from route params if available
    if (this.$route.params.tab) {
      this.activeTab = this.$route.params.tab;
    }
  },

  components: {
    TitleBar
  },

  computed: {
    titleStack() {
      return [{ name: "Dashboard", url: null }];
    },
    ...mapGetters(["isUserAgent", "isUserAdmin", "isUserSuper"])
  },
  methods: {
    updateTabCount(tabId, event) {
      const tab = this.tabs.find(t => t.id === event.id);
      if (tab) {
        tab.count = event.count;
      }
    }
  },
  watch: {
    // Watch for route changes to update active tab
    "$route.params.tab": {
      handler(newTab) {
        if (newTab) {
          this.activeTab = newTab;
        }
      },
      immediate: true
    },
    // Update watcher for activeTab to use /dashboard/:tab
    activeTab(newTab) {
      if (newTab === "widgets") {
        this.$router.push("/");
      } else {
        this.$router.push(`/dashboard/${newTab}`);
      }
    }
  }
};
</script>
<style lang="scss">
.dashboard {
  padding: 1rem !important;
  .b-tabs .tab-content {
    padding: 0.1rem 0 0 0 !important;
  }

  .notification-badge {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 12px;
    margin-left: 5px;
  }
}
</style>
