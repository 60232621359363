<template>
  <div class="mt-5">
    <div class="">
      <b-field grouped>
        <b-field label="Velg synlige statuser" grouped>
          <b-taginput
            v-model="filters.status"
            :data="filteredStatus"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            :allow-duplicates="false"
            icon="label"
            type="is-dark"
            field="translated"
            :placeholder="
              `${filters.status.length < 1 ? 'Klikk for å legge til' : ''}`
            "
            @typing="getFilteredStatus"
            @focus="getFilteredStatus()"
            @input="loadAsyncData"
          >
          </b-taginput>
        </b-field>

        <b-field label="Velg synlige oppdragstyper" grouped>
          <b-taginput
            v-model="filters.repossessionType"
            :data="filteredRepossessionType"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            :allow-duplicates="false"
            icon="label"
            type="is-dark"
            field="translated"
            :placeholder="
              `${
                filters.repossessionType.length < 1
                  ? 'Klikk for å legge til'
                  : ''
              }`
            "
            @typing="getFilteredRepossessionType"
            @focus="getFilteredRepossessionType()"
            @input="loadAsyncData"
          >
          </b-taginput>
        </b-field>
      </b-field>

      <b-table
        :mobile-cards="false"
        class="is-striped"
        ref="table"
        :data="items"
        :hoverable="true"
        :loading="isLoading"
        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        aria-next-label="Neste side"
        aria-previous-label="Forrige side"
        aria-page-label="side"
        aria-current-label="Gjeldende side"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
      >
        <section slot="bottom-left">
          Viser <strong>{{ items.length }}</strong> av totalt
          <strong>{{ total }}</strong> objekter
        </section>

        <b-table-column field="id" label="ID" v-slot="props" sortable>
          <router-link
            v-if="props.row.id"
            :to="{ name: 'item.edit', params: { id: props.row.id } }"
          >
            {{ props.row.id }}
          </router-link>
        </b-table-column>

        <b-table-column field="status" label="Status" v-slot="props">
          {{ $t(`items.status.${props.row.status}`) }}
        </b-table-column>

        <b-table-column
          field="repossessionType"
          label="Oppdragstype"
          v-slot="props"
          :sortable="false"
        >
          {{ $t(`items.repossessionType.${props.row.repossessionType}`) }}
        </b-table-column>

        <b-table-column
          field="createdAt"
          sortable
          label="Opprettet"
          v-slot="props"
        >
          <span class="is-size-7">
            {{ getFormattedDateTime(props.row.createdAt) }}
          </span>
        </b-table-column>

        <b-table-column
          centered
          v-for="(module, index) in $itemModules.filter(
            module => module !== 'museum'
          )"
          :key="index"
          :label="$t(`items.${module}`)"
          v-slot="props"
          cell-class="is-vcentered"
        >
          <div v-if="props.row[module]?.status">
            <router-link
              v-if="props.row.id"
              :to="{
                name: `item.edit.${module}`,
                params: { id: props.row.id }
              }"
            >
              <b-progress
                style="min-width: 100px"
                :type="
                  progressColor(
                    props.row[module]?.expiration,
                    module,
                    props.row.createdAt,
                    props.row[module]?.progress
                  )
                "
                :value="props.row[module]?.progress"
                show-value
                ><span
                  v-if="props.row[module]?.expiration"
                  class="has-text-weight-bold"
                >
                  {{
                    getFormattedDateTime(
                      props.row[module]?.expiration,
                      "DD.MM.YYYY"
                    )
                  }}
                </span>
                <span v-else>
                  {{ $t(`items.${module}Module.${props.row[module]?.status}`) }}
                  {{ props.row[module]?.progress }}%
                </span></b-progress
              >
            </router-link>
          </div>
          <div v-else>
            <span class="is-size-7 p-0 m-0">Ikke aktiv</span>
          </div>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import helpers from "../../helpers";
import LogText from "../../components/LogText.vue";

export default {
  mixins: [helpers],
  components: {
    LogText
  },

  data() {
    return {
      items: [],
      total: 0,
      sortField: "createdAt",
      sortOrder: "asc",
      defaultSortOrder: "desc",
      defaultStatuses: [],
      defaultRepossessionTypes: [],
      filteredStatus: [],
      filteredRepossessionType: [],
      filters: {
        status: [],
        repossessionType: []
      },
      page: 1,
      perPage: 20,
      isLoading: false
    };
  },

  props: {
    id: {
      default: null
    }
  },

  mounted() {
    this.loadAsyncData();
  },
  computed: {
    transitionName() {
      if (this.useTransition) {
        return "fade";
      }
    }
  },

  methods: {
    sortedAndTranslated(arr, translationPath) {
      // Sort the array alphabetically
      const sortedArr = arr.sort((a, b) => {
        const aTranslation = this.$t(`${translationPath}.${a}`);
        const bTranslation = this.$t(`${translationPath}.${b}`);

        return aTranslation.localeCompare(bTranslation);
      });

      return sortedArr.map(opt => ({
        original: opt,
        translated: this.$t(`${translationPath}.${opt}`)
      }));
    },

    getFilteredRepossessionType(text) {
      this.filteredRepossessionType = this.defaultRepossessionTypes.filter(
        option => {
          let hasText = text
            ? option.translated.toLowerCase().indexOf(text.toLowerCase()) >= 0
            : true;

          let existing = !this.filters.repossessionType
            .map(opt => opt.original)
            .includes(option.original);

          return hasText && existing;
        }
      );
    },

    getFilteredStatus(text) {
      this.filteredStatus = this.defaultStatuses.filter(option => {
        let hasText = text
          ? option.translated.toLowerCase().indexOf(text.toLowerCase()) >= 0
          : true;

        let existing = !this.filters.status
          .map(opt => opt.original)
          .includes(option.original);

        return hasText && existing;
      });
    },

    statusColor(status, date) {
      // format date to date object
      date = new Date(date);

      switch (status) {
        case "onitsway":
        case "preparation":
          if (date < this.getDateXDaysAgo(7)) {
            return "is-danger";
          }
          break;

        case "readyforsale":
          if (date < this.getDateXDaysAgo(10)) {
            return "is-danger";
          }
          break;

        case "inprocess":
        case "fordelivery":
        case "sold":
          if (date < this.getDateXDaysAgo(10)) {
            return "is-danger";
          }
      }
    },

    loadAsyncData() {
      this.isLoading = true;
      this.$axios
        .get(`${window.apiURL}/admin/dashboard/items`, {
          params: {
            filters: this.filters,
            page: this.page,
            perPage: this.perPage,
            sortField: this.sortField,
            sortOrder: this.sortOrder
          }
        })
        .then(response => {
          this.items = response.data.items;
          let currentTotal = response.data.total;

          if (!this.defaultStatuses.length) {
            this.defaultStatuses = this.sortedAndTranslated(
              response.data.statuses,
              "items.status"
            );
          }

          if (!this.defaultRepossessionTypes.length) {
            this.defaultRepossessionTypes = this.sortedAndTranslated(
              response.data.repossessionTypes,
              "items.repossessionType"
            );
          }

          if (response.data.total / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          this.total = currentTotal;
        })
        .catch(e => {
          this.logs = [];
          this.total = 0;
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    }
  }
};
</script>

<style lang="scss" scoped></style>
